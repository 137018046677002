import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId, cart = [], newClient, company, restartUrl } = location.state || {};

  const [showRestartModal, setShowRestartModal] = useState(false);
  const [paymentOption, setPaymentOption] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [billingAddress, setBillingAddress] = useState({
    zipCode: '',
    line1: '',
    city: '',
    state: '',
    country: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pixUrl, setPixUrl] = useState('');
  const [pixCopyPaste, setPixCopyPaste] = useState('');
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);

  const totalAmount = cart.length > 0 ? cart.reduce((total, item) => total + (item.price * item.quantity), 0) : 0;

  const paymentMethods = company?.payment_methods || [];
  const isPosEnabled = paymentMethods.includes('pos');
  const isPixEnabled = paymentMethods.includes('pix');
  const isCreditCardEnabled = paymentMethods.includes('credit_card');

  useEffect(() => {
    if (isPosEnabled) {
      setPaymentOption('pos');
    } else if (isPixEnabled) {
      setPaymentOption('pix');
    } else if (isCreditCardEnabled) {
      setPaymentOption('credit_card');
    }
  }, [isPosEnabled, isPixEnabled, isCreditCardEnabled]);

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
    setCardNumber(value);
  };

  const handleExpirationDateChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').replace(/(\d{2})(\d{1,2})/, '$1/$2');
    setExpirationDate(value);
  };

  const handleOpenRestartModal = () => {
    setShowRestartModal(true);
  };

  const handleCloseRestartModal = () => {
    setShowRestartModal(false);
  };

  const getAddressByCep = async (zip) => {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${zip}/json/`);
      if (response.data.erro) {
        throw new Error('CEP não encontrado');
      }
      return {
        street: response.data.logradouro,
        city: response.data.localidade,
        state: response.data.uf,
        country: 'Brasil', // Assuming the country is Brazil for all addresses
      };
    } catch (error) {
      console.error('Erro ao buscar endereço pelo CEP:', error);
      toast.error('Erro ao buscar endereço. Verifique o CEP e tente novamente.');
      return {
        street: '',
        city: '',
        state: '',
        country: '',
      };
    }
  };

  const handleZipCodeChange = async (e) => {
    const zip = e.target.value;
    setBillingAddress(prevState => ({ ...prevState, zipCode: zip }));

    if (zip.length >= 8) {
      const addressInfo = await getAddressByCep(zip);
      setBillingAddress(prevState => ({
        ...prevState,
        line1: addressInfo.street,
        city: addressInfo.city,
        state: addressInfo.state,
        country: addressInfo.country,
        zipCode: zip
      }));
    }
  };

  const handleBillingAddressChange = (e) => {
    const { name, value } = e.target;
    setBillingAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(pixCopyPaste);
    toast.success('Código PIX copiado para a área de transferência!');
  };

  const handleSubmitCreditCard = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const payer = {
        document: e.target.document.value,
        phone: {
          area_code: newClient.phone.slice(0, 2),
          number: newClient.phone.slice(2),
        },
        first_name: newClient.name.split(' ')[0],
        last_name: newClient.name.split(' ').slice(-1)[0],
        email: newClient.email,
        address: {
          zip_code: newClient.zip_code,
          street_name: newClient.full_address,
        },
      };

      const cardInfo = {
        number: cardNumber.replace(/\s/g, ''), // Remove spaces before sending
        holderName: e.target.cardholderName.value,
        expirationDate: expirationDate,
        cvv: e.target.cvv.value,
        billingAddress: {
          line1: billingAddress.line1,
          zipCode: billingAddress.zipCode,
          city: billingAddress.city,
          state: billingAddress.state,
          country: billingAddress.country,
        },
      };

      const cardHashResponse = await axios.post(`${apiBaseUrl}/generate-card-hash`, cardInfo);
      const cardHash = cardHashResponse.data.cardHash;

      const response = await axios.post(`${apiBaseUrl}/orders/payment-method/${orderId}`, {
        paymentMethod: 'credit_card',
        cardHash,
        cardInfo,
        totalAmount,
        payer,
      });

      if (response.status === 200) {
        toast.success('Pagamento com cartão aprovado!');
        setIsPaymentCompleted(true);
      } else {
        toast.error('Erro ao processar pagamento com cartão.');
      }
    } catch (error) {
      console.error('Erro ao processar pagamento com cartão', error);
      toast.error('Erro ao processar o pagamento. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePixGenerate = async () => {
    setIsSubmitting(true);
    try {
      const payer = {
        document: '',
        phone: {
          area_code: newClient.phone.slice(0, 2),
          number: newClient.phone.slice(2),
        },
        first_name: newClient.name.split(' ')[0],
        last_name: newClient.name.split(' ').slice(-1)[0],
        email: newClient.email,
        address: {
          zip_code: newClient.zip_code,
          street_name: newClient.full_address,
        },
      };

      const response = await axios.post(`${apiBaseUrl}/orders/payment-method/${orderId}`, {
        paymentMethod: 'pix',
        totalAmount,
        payer,
      });

      if (response.data) {
        setPixUrl(response.data.charges[0].last_transaction.qr_code_url);
        setPixCopyPaste(response.data.charges[0].last_transaction.qr_code);
        toast.success('PIX QR Code gerado com sucesso!');
        setIsPaymentCompleted(true);
      } else {
        toast.error('Erro ao gerar PIX QR Code.');
      }
    } catch (error) {
      console.error('Erro ao gerar PIX QR Code', error);
      toast.error('Erro ao gerar PIX QR Code. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePosPayment = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${apiBaseUrl}/orders/payment-method/${orderId}`, { paymentMethod: paymentOption });

      if (response.status === 200) {
        toast.success('Pagamento com maquininha efetuado!');
        setIsPaymentCompleted(true);
      } else {
        toast.error('Erro ao processar pagamento com maquininha.');
      }
    } catch (error) {
      console.error('Erro ao processar pagamento com maquininha', error);
      toast.error('Erro ao processar pagamento com maquininha. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitPayment = async () => {
    try {
      await axios.post(`${apiBaseUrl}/orders/status/${orderId}`, { status: 'placed' });
      toast.success('Seu pedido foi concluído!');
      setTimeout(() => {
        navigate(restartUrl);
      }, 1000);
    } catch (error) {
      console.error('Failed to create order', error);
      toast.error('Erro ao confirmar pagamento. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="checkout-container">
      <h1>Pagamento</h1>
      {(!isPosEnabled && !isPixEnabled && !isCreditCardEnabled) ? (
        <p>Não há métodos de pagamento disponíveis</p>
      ) : (
        <div className="delivery-options">
          <label className={`delivery-option ${!isPosEnabled ? 'disabled' : ''}`}>
            <input
              type="radio"
              name="paymentOption"
              value="pos"
              checked={paymentOption === 'pos'}
              onChange={() => setPaymentOption('pos')}
              disabled={!isPosEnabled || isPaymentCompleted}
            />
            Maquininha
          </label>
          <label className={`delivery-option ${!isPixEnabled ? 'disabled' : ''}`}>
            <input
              type="radio"
              name="paymentOption"
              value="pix"
              checked={paymentOption === 'pix'}
              onChange={() => setPaymentOption('pix')}
              disabled={!isPixEnabled || isPaymentCompleted}
            />
            PIX
          </label>
          <label className={`delivery-option ${!isCreditCardEnabled ? 'disabled' : ''}`}>
            <input
              type="radio"
              name="paymentOption"
              value="credit_card"
              checked={paymentOption === 'credit_card'}
              onChange={() => setPaymentOption('credit_card')}
              disabled={!isCreditCardEnabled || isPaymentCompleted}
            />
            Cartão de crédito
          </label>
        </div>
      )}
      {paymentOption === 'pix' && (
        <div className="pix-section">
          <h3>PIX QR Code</h3>
          {pixUrl && <img src={pixUrl} alt="PIX QR Code" style={{ width: '256px', height: '256px' }} />}
          <h3>Ou copie o código PIX</h3>
          <div 
            className="pix-code-box" 
            onClick={handleCopy} 
            style={{ cursor: 'pointer' }}>
            {pixCopyPaste || 'Clique em "Gerar PIX" para gerar o código'}
          </div>
          {!isPaymentCompleted && <button 
            onClick={handlePixGenerate} 
            disabled={isSubmitting} 
            style={{ opacity: isSubmitting ? 0.6 : 1, cursor: isSubmitting ? 'not-allowed' : 'pointer' }}>
            Gerar PIX
          </button>}
        </div>
      )}
      {!isPaymentCompleted && paymentOption === 'pos' && (
        <div className="pos-section">
          <button 
            onClick={handlePosPayment} 
            disabled={isSubmitting} 
            style={{ opacity: isSubmitting ? 0.6 : 1, cursor: isSubmitting ? 'not-allowed' : 'pointer' }}>
            Efetuar na maquininha
          </button>
        </div>
      )}
      {paymentOption === 'credit_card' && (
        <div className="credit-card">
          <h3>Insira os detalhes do cartão de crédito</h3>
          <form onSubmit={handleSubmitCreditCard}>
            <input
              type="text"
              name="zipCode"
              placeholder="CEP"
              value={billingAddress.zipCode}
              onChange={handleZipCodeChange}
              required
            />
            <input
              type="text"
              name="line1"
              placeholder="Endereço de cobrança"
              value={billingAddress.line1}
              onChange={handleBillingAddressChange}
              required
            />
            <input
              type="text"
              name="city"
              placeholder="Cidade"
              value={billingAddress.city}
              onChange={handleBillingAddressChange}
              required
            />
            <input
              type="text"
              name="state"
              placeholder="Estado"
              value={billingAddress.state}
              onChange={handleBillingAddressChange}
              required
            />
            <input
              type="text"
              name="country"
              placeholder="País"
              value={billingAddress.country}
              onChange={handleBillingAddressChange}
              required
            />
            <input
              type="text"
              name="cardholderName"
              placeholder="Nome no cartão"
              required
            />
            <input
              type="text"
              name="document"
              placeholder="CPF do titular"
              required
            />
            <input
              type="text"
              name="cardNumber"
              placeholder="Número do cartão"
              value={cardNumber}
              onChange={handleCardNumberChange}
              required
            />
            <input
              type="text"
              name="expirationDate"
              placeholder="MM/AA"
              value={expirationDate}
              onChange={handleExpirationDateChange}
              required
            />
            <input
              type="text"
              name="cvv"
              placeholder="CVV"
              required
            />
            {!isPaymentCompleted && <button 
              type="submit" 
              disabled={isSubmitting} 
              style={{ opacity: isSubmitting ? 0.6 : 1, cursor: isSubmitting ? 'not-allowed' : 'pointer' }}>
              Pagar com cartão de crédito
            </button>}
          </form>
        </div>
      )}
      {isPaymentCompleted && (
        <div className="checkout-submit">
          <button onClick={handleSubmitPayment} className="checkout-submit-button">
            Concluir pedido pago
          </button>
        </div>
      )}
      <ToastContainer />

      <div>
        <button className="recomecar-button" onClick={handleOpenRestartModal}>
          <span className="recomecar-text">↺ Recomeçar</span>
        </button>
      </div>

      {showRestartModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseRestartModal}>×</button>
            <p>Deseja remover todos os itens do seu carrinho e recomeçar?</p>
            <div className="modal-actions">
              <button className="cancel-restart" onClick={handleCloseRestartModal}>Não</button>
              <button className="confirm-restart" onClick=
                {() => {
                  navigate(restartUrl);
                  setShowRestartModal(false);
                }}>Sim
              </button>
            </div>
          </div>
        </div>
      )}

      <style>{`
        .checkout-container {
          min-height: 100vh;
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          font-family: Arial, sans-serif;
        }
        .delivery-options {
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
        }
        .delivery-option {
          display: flex;
          align-items: center;
          font-weight: bold;
          cursor: pointer;
        }
        .delivery-option input {
          margin-right: 10px;
          cursor: pointer;
        }
        .disabled {
          opacity: 0.6;
          pointer-events: none;
        }
        .pix-section {
          text-align: center;
          margin-top: 20px;
        }
        .pos-section {
          text-align: center;
          margin-top: 20px;
        }
        .pix-code-box {
          height: auto;
          font-size: 1rem;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          background-color: #f9f9f9;
          margin-top: 10px;
          word-wrap: break-word;
          transition: background-color 0.3s;
        }
        .pix-code-box:hover {
          background-color: #e0e0e0; /* Lighter color to indicate hover */
        }
        .checkout-submit {
          margin-top: 20px;
          display: flex;
          justify-content: center;
        }
        .checkout-submit-button {
          padding: 10px;
          font-size: 1rem;
          background-color: #007bff;
          color: white;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
          transition: background-color 0.3s, color 0.3s;
        }
        .checkout-submit-button:hover {
          background-color: #ddd;
        }
        .credit-card input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .credit-card button {
          padding: 10px;
          font-size: 1rem;
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
          transition: background-color 0.3s, color 0.3s;
        }
        .pix-section button {
          padding: 10px;
          font-size: 1rem;
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
          transition: background-color 0.3s, color 0.3s;
        }
        .pos-section button {
          padding: 10px;
          font-size: 1rem;
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
          transition: background-color 0.3s, color 0.3s;
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 5px;
          max-width: 400px;
          width: 100%;
          position: relative;
        }
        .close-modal {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 1.5rem;
          background: none;
          border: none;
          cursor: pointer;
        }
        .modal-actions {
          display: flex;
          justify-content: center;
          gap: 15px;
          margin-top: 20px;
        }
        .cancel-restart {
          background-color: #e0e0e0;
          color: black;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .confirm-restart {
          background-color: #333;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .recomecar-button {
          width: 30%;
          padding: 15px 20px;
          background-color: #333;
          color: #fff;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s;
          position: fixed;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
        }
        .recomecar-button:hover {
          background-color: #333;
          opacity: 0.75;
        }
      `}</style>
    </div>
  );
};

export default PaymentPage;
