import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './components/SignInPage';
import SignUpPage from './components/SignUpPage';
import EditCatalogPage from './components/EditCatalogPage';
import ClientsList from './components/ClientsList';
import CatalogPage from './components/CatalogPage';
import ShopPage from './components/ShopPage';
import CheckoutPage from './components/CheckoutPage';
import PaymentPage from './components/PaymentPage';

const App = () => {
  const [companyId, setCompanyId] = useState(null);

  const handleSignIn = (id) => {
    setCompanyId(id);
  };

  const handleSignOut = () => {
    setCompanyId(null);
  };

  return (
    <Router>
      <MainRoutes companyId={companyId} onSignIn={handleSignIn} onSignOut={handleSignOut} />
    </Router>
  );
};

const MainRoutes = ({ companyId, onSignIn, onSignOut }) => {
  return (
    <Routes>
      <Route path="/signin" element={<SignIn onSignIn={onSignIn} />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/edit_catalog/:catalogSlug" element={<EditCatalogPage />} />
      <Route path="/clients/:companyId" element={<ClientsList onSignOut={onSignOut} />} />
      <Route path="/catalogs/:catalogSlug" element={<CatalogPage />} />
      <Route path="/shop/:catalogSlug" element={<ShopPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};

export default App;