import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

// API functions
const getCatalogBySlug = async (slug) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/catalogs/slug/${slug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching catalog:', error);
    return null;
  }
};

const getProductsByCatalogSlug = async (catalogSlug) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/products/slug/${catalogSlug}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};

const Catalog = (props) => {
  const { catalogSlug: paramSlug } = useParams();
  const catalogSlug = props.catalogSlug || paramSlug;

  const location = useLocation();
  const navigate = useNavigate();

  const [catalog, setCatalog] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [showRestOverlay, setShowRestOverlay] = useState(false);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [cartLastActivityTime, setCartLastActivityTime] = useState(Date.now());
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const fetchCatalogData = async () => {
      const searchParams = new URLSearchParams(location.search);
      setName(searchParams.get('name'));
      setPhone(searchParams.get('phone'));
      setEmail(searchParams.get('email'));
  
      const catalogData = await getCatalogBySlug(catalogSlug);
      setCatalog(catalogData);
  
      const companyId = catalogData.company_id;
      setCompanyId(companyId);
  
      const productsData = await getProductsByCatalogSlug(catalogSlug);
      setProducts(productsData);
  
      // Extract product IDs and quantities from URL
      const productParams = searchParams.get('products');
      const filteredParams = productParams ? productParams.split(',') : [];
  
      // Map the product data with quantities loaded from the URL
      if (filteredParams.length > 0) {
        const selectedProducts = filteredParams.map(param => {
          const [productId, quantity] = param.split(':');
          const product = productsData.find(p => p.id === productId);
          
          if (product) {
            return {
              ...product,
              quantity: parseInt(quantity, 10) || 1, // Ensure quantity is parsed correctly
            };
          }
          return null;
        }).filter(item => item !== null);
  
        setCart(selectedProducts);
      }
  
      // Set the categories from the products
      let uniqueCategories = [...new Set(productsData.flatMap(product => product.categories))];
  
      if (uniqueCategories.includes('Serviços')) {
        uniqueCategories = ['Serviços', ...uniqueCategories.filter(cat => cat !== 'Serviços')];
      }
  
      setCategories(['All', ...uniqueCategories]);
  
      const initialImageIndex = {};
      productsData.forEach(product => {
        initialImageIndex[product.id] = 0;
      });
      setCurrentImageIndex(initialImageIndex);
    };
  
    fetchCatalogData();
  }, [catalogSlug, location.search]);

  useEffect(() => {
    const handleUserActivity = () => {
      setLastActivityTime(Date.now());
      setCartLastActivityTime(Date.now());
      setShowRestOverlay(false);
    };

    const checkInactivity = () => {
      const currentTime = Date.now();
      if (currentTime - lastActivityTime > 180000) {
        setShowRestOverlay(true);
      }

      if (currentTime - cartLastActivityTime > 3600000) {
        setCart([]);
      }
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    const interval = setInterval(checkInactivity, 1000);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearInterval(interval);
    };
  }, [lastActivityTime, cartLastActivityTime]);

  const handleNextImage = (productId) => {
    setCurrentImageIndex((prev) => {
      const newIndex = (prev[productId] + 1) % products.find(p => p.id === productId).image_urls.length;
      return { ...prev, [productId]: newIndex };
    });
  };

  const handlePrevImage = (productId) => {
    setCurrentImageIndex((prev) => {
      const product = products.find(p => p.id === productId);
      const newIndex = (prev[productId] - 1 + product.image_urls.length) % product.image_urls.length;
      return { ...prev, [productId]: newIndex };
    });
  };

  const handleModalNextImage = () => {
    if (selectedProduct) {
      setModalImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct.image_urls.length);
    }
  };

  const handleModalPrevImage = () => {
    if (selectedProduct) {
      setModalImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct.image_urls.length) % selectedProduct.image_urls.length);
    }
  };

  const handleAddToCart = () => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find(item => item.id === selectedProduct.id);
      if (existingProduct) {
        // Update the quantity of the existing product
        return prevCart.map(item =>
          item.id === selectedProduct.id ? { ...item, quantity } : item
        );
      } else {
        // Add new product to the cart
        return [...prevCart, { ...selectedProduct, quantity }];
      }
    });
  
    setCartLastActivityTime(Date.now());
    setSelectedProduct(null);
    setQuantity(1);
    setModalImageIndex(0);
  
    // Update URL with selected product IDs and quantities
    const searchParams = new URLSearchParams(location.search);
    const productParams = cart
      .map(item => `${item.id}:${item.quantity}`)
      .concat(`${selectedProduct.id}:${quantity}`)
      .join(',');
    
    searchParams.set('products', productParams);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        if (selectedProduct) {
          setSelectedProduct(null);
        }
        if (showDeleteModal) {
          setShowDeleteModal(false);
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedProduct, showDeleteModal]);

  const handleCheckout = () => {
    const productParams = cart.map(item => `${item.id}:${item.quantity}`).join(',');
    const searchParams = new URLSearchParams();
  
    searchParams.set('products', productParams);
    searchParams.set('companyId', companyId);
    searchParams.set('catalogSlug', catalog.slug);
    if (phone) searchParams.set('phone', phone);
    if (name) searchParams.set('name', name);
    if (email) searchParams.set('email', email);
  
    navigate(`/checkout?${searchParams.toString()}`);
  };

  const handleReturnToCatalog = () => {
    setShowRestOverlay(false);
    setLastActivityTime(Date.now());
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDeleteCart = () => {
    setCart([]); // Clear the cart
    setShowDeleteModal(false); // Close the modal
  };

  const filteredProducts = products
  .filter(product => 
    selectedCategory === 'All' || product.categories.includes(selectedCategory)
  )
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(product => {
    const cartProduct = cart.find(item => item.id === product.id);
    return {
      ...product,
      quantity: cartProduct ? cartProduct.quantity : 0,
    };
  });

  const carouselProducts = products.filter(product => !product.categories.includes('Serviços')).map((product, index) => (
    <div key={index} className="carousel-product-card">
      <img src={product.image_urls[0]} alt={product.name} />
      <h2>{product.name}</h2>
      <p>R$ {product.price}</p>
    </div>
  ));

  return (
    <div className="container">
      {showRestOverlay ? (
        <div className="rest-overlay">
          {catalog && (
            <div className="catalog-header">
              <img src={catalog.image_url} alt={catalog.name} className="catalog-image" />
              <h2 className="rest-message">
                {catalog?.rest_message || 'Veja aqui os items do nosso catálogo'}
              </h2>
            </div>
          )}
          <div className="rest-content">
            <div className="qr-and-text">
              <QRCode
                value={`http://www.estoque-digital.com/catalogs/${catalog?.slug}`}
                size={156.25}
              />
              <p className="scan-text">Escaneie para ver no celular</p>
              <button className="return-button" onClick={handleReturnToCatalog}>
                {cart.length > 0 ? 'Voltar ao catálogo' : 'Acessar o catálogo'}
              </button>
            </div>
          </div>
          <div className="carousel-container">
            <div className="carousel">
              {carouselProducts}
            </div>
          </div>
        </div>
      ) : (
        <div className="catalog-content">
          {catalog && (
            <div className="catalog-header">
              <img src={catalog.image_url} alt={catalog.name} className="catalog-image" />
              <div className="catalog-titles">
                <h1 className="catalog-name">{catalog.name}</h1>
                <h2 className="catalog-description">{catalog.description}</h2>
              </div>
            </div>
          )}

          <nav className="categories-navbar">
            {categories.map((category, index) => (
              <button
                key={index}
                className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </nav>

          <div className="products-container">
          {filteredProducts.map((product) => (
            <div
              key={product.id}
              className="product-card"
              onClick={() => { setSelectedProduct(product); setModalImageIndex(0); }}
            >
              <div className="image-scroll-container">
                <div className="image-wrapper" style={{ transform: `translateX(-${currentImageIndex[product.id] * 100}%)`, width: '100%' }}>
                  {product.image_urls.map((url, index) => (
                    <div key={index} className="image-slide">
                      <img
                        src={url}
                        alt={product.name}
                        className="product-image"
                      />
                    </div>
                  ))}
                </div>
                {currentImageIndex[product.id] > 0 && (
                  <button className="arrow-button left" onClick={(e) => { e.stopPropagation(); handlePrevImage(product.id); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                )}
                {currentImageIndex[product.id] < product.image_urls.length - 1 && (
                  <button className="arrow-button right" onClick={(e) => { e.stopPropagation(); handleNextImage(product.id); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                )}
              </div>

              <div className="product-info">
                <h2>{product.name}</h2>
                <p className="product-price">R$ {product.price}</p>
              </div>

              {product.quantity > 0 && (
                <div className="quantity-indicator">
                  {product.quantity}
                </div>
              )}
            </div>
          ))}
          </div>

          <div style={{ marginBottom: '75px' }}></div>

          <div className="action-buttons">
            <button
              className="checkout-button"
              onClick={handleCheckout}
              disabled={cart.length === 0}
            >
              Checkout
            </button>
            <button
              className="trash-button"
              onClick={handleOpenDeleteModal}
              disabled={cart.length === 0}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>

          {showDeleteModal && (
            <div className="modal">
              <div className="modal-content">
                <button className="close-modal" onClick={handleCloseDeleteModal}>×</button>
                <h2>Remover Itens</h2>
                <p>Deseja remover {cart.length} itens do seu carrinho?</p>
                <div className="modal-actions">
                  <button className="cancel-delete" onClick={handleCloseDeleteModal}>Não</button>
                  <button className="confirm-delete" onClick={handleConfirmDeleteCart}>Sim</button>
                </div>
              </div>
            </div>
          )}

          {selectedProduct && (
            <div className="modal">
              <div className="modal-content">
                <button className="close-modal" onClick={() => setSelectedProduct(null)}>×</button>
                <h2>{selectedProduct.name}</h2>
                <div className="modal-image-container">
                  <div className="modal-image-wrapper" style={{ transform: `translateX(-${modalImageIndex * 100}%)`, width: '100%' }}>
                    {selectedProduct.image_urls.map((url, index) => (
                      <div key={index} className="image-slide">
                        <img
                          src={url}
                          alt={selectedProduct.name}
                          className="modal-image"
                        />
                      </div>
                    ))}
                  </div>
                  {modalImageIndex > 0 && (
                    <button className="modal-arrow left" onClick={handleModalPrevImage}>
                      ‹
                    </button>
                  )}
                  {modalImageIndex < selectedProduct.image_urls.length - 1 && (
                    <button className="modal-arrow right" onClick={handleModalNextImage}>
                      ›
                    </button>
                  )}
                </div>
                <p className="modal-price">Individual: R$ {selectedProduct.price}</p>
                <p className="modal-total-price">Total: R$ {(selectedProduct.price * quantity).toFixed(2)}</p>
                <div className="quantity-selector">
                  <button onClick={() => setQuantity(Math.max(quantity - 1, 1))}>-</button>
                  <span>{quantity}</span>
                  <button onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
                <button className="add-to-cart" onClick={handleAddToCart}>Adicionar ao carrinho</button>
              </div>
            </div>
          )}
        </div>
      )}
      <style>{`
        .container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 10px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          font-family: Arial, sans-serif;
          overflow-y: scroll;
          padding-bottom: 100px;
        }

        .catalog-header {
          display: flex-column;
          align-items: center;
          text-align: center;
          margin-bottom: 10px;
        }

        .catalog-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          border: 3px solid ${catalog?.theme_color ? catalog.theme_color : '#333'};
        }

        .catalog-name {
          font-size: 1.75rem;
          font-weight: bold;
          color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          margin: 0;
        }

        .catalog-description {
          font-size: 1rem;
          color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          opacity: 0.75;
        }

        .categories-navbar {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          margin-bottom: 20px;
          border-bottom: 2px solid ${catalog?.theme_color ? catalog.theme_color : '#333'};
          border-top: 2px solid ${catalog?.theme_color ? catalog.theme_color : '#333'};
          position: relative;
          font-size: 1.1rem;
          -webkit-overflow-scrolling: touch;
        }

        .category-button {
          margin: 5px;
          padding: 10px 20px;
          background-color: transparent;
          color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          border: none;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          transition: color 0.3s, border-bottom-color 0.3s;
          flex-shrink: 0;
          white-space: nowrap;
        }

        .category-button:hover, .category-button.active {
          color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          font-weight: bold;
          border-bottom: 2px solid ${catalog?.theme_color ? catalog.theme_color : '#333'};
        }

        .products-container {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }

        .product-card {
          height: 350px;
          background-color: #fff;
          border-radius: 15px;
          border: 1px solid #ddd;
          padding: 20px;
          box-shadow: 0 0 10px ${catalog?.theme_color ? catalog.theme_color : '#333'};
          width: calc(33.333% - 20px);
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          position: relative;
          justify-content: space-between;
        }

        @media (max-width: 768px) {
          .product-card {
            width: calc(50% - 20px);
          }
        }

        .image-scroll-container {
          display: flex;
          justify-content: center;
          overflow: hidden;
          height: 70%;
          width: 100%;
        }

        .image-wrapper {
          display: flex;
          transition: transform 0.2s ease;
        }

        .image-slide {
          flex-shrink: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .product-image {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        }

        .arrow-button {
          position: absolute;
          background-color: transparent;
          border: none;
          padding: 10px;
          cursor: pointer;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);
        }

        .arrow-button svg {
          width: 28px;
          height: 28px;
          color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
        }

        .arrow-button.left {
          left: 0px;
        }

        .arrow-button.right {
          right: 0px;
        }

        .product-info {
          text-align: center;
          margin-top: 10px;
        }

        .product-info h2 {
          margin: 0 0 5px;
          font-size: 1.2rem;
        }

        .product-price {
          margin: 0;
          color: #333;
          font-weight: bold;
          font-size: 1.2rem;
        }

        .quantity-indicator {
          position: absolute;
          bottom: 10px;
          right: 10px;
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          color: white;
          border-radius: 50%;
          padding: 5px;
          font-size: 0.8rem;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .carousel-container {
          width: 100%;
          overflow: hidden;
          position: relative;
          margin-bottom: 20px;
        }

        .carousel {
          display: flex;
          animation: scroll 20s linear infinite;
          width: max-content;
          margin-bottom: 500px;
        }

        @keyframes scroll {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(calc(-100%));
          }
        }

        .carousel-product-card {
          flex: 0 0 auto;
          width: 150px; /* Fixed width for each product block */
          padding: 10px;
          text-align: center;
          color: black;
          word-wrap: break-word;
          white-space: normal;
        }

        .carousel-product-card img {
          width: 100%; /* Fit the image to the width of the container */
          height: 150px; /* Fixed height to make the container square */
          object-fit: contain; /* Ensure the image scales down to fit within the container without cropping */
          display: block;
          margin: 0 auto 10px auto; /* Center the image within the product block */
        }

        .carousel-product-card h3, 
        .carousel-product-card p {
          margin: 5px 0;
          font-size: 20px;
          line-height: 1.2;
          font-weight: bold;
        }
        .action-buttons {
          position: fixed;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          max-width: 400px;
          display: flex;
          justify-content: center;
          gap: 10px;
          z-index: 1000;
        }

        .checkout-button {
          width: 70%;
          padding: 15px 20px;
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          color: #fff;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          font-size: 1.2rem;
          transition: background-color 0.3s;
        }

        .checkout-button:hover {
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          opacity: 0.75;
        }

        .checkout-button:disabled {
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          cursor: not-allowed;
          opacity: 0.3;
        }

        .trash-button {
          width: 50px;
          height: 50px;
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          color: #fff;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          font-size: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .trash-button:disabled {
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          cursor: not-allowed;
          opacity: 0.3;
        }

        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .modal-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 5px;
          max-width: 500px;
          width: 100%;
          position: relative;
        }

        .close-modal {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 1.5rem;
          background: none;
          border: none;
          cursor: pointer;
        }

        .modal-price, .modal-total-price {
          font-size: 1.5rem;
          font-weight: bold;
          margin: 10px 0;
        }

        .modal-image-container {
          position: relative;
          width: 100%;
          overflow: hidden;
        }

        .modal-image-wrapper {
          display: flex;
          transition: transform 0.5s ease;
        }

        .modal-image {
          max-width: 100%;
          max-height: 500px;
          object-fit: contain;
          display: block;
        }

        .modal-arrow {
          position: absolute;
          background-color: transparent;
          border: none;
          padding: 10px;
          cursor: pointer;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%);
          font-size: 2rem;
        }

        .modal-arrow.left {
          left: 10px;
        }

        .modal-arrow.right {
          right: 10px;
        }

        .quantity-selector {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;
        }

        .quantity-selector button {
          padding: 5px 10px;
          font-size: 1.2rem;
        }

        .quantity-selector span {
          font-size: 1.2rem;
        }

        .add-to-cart {
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          color: #fff;
          padding: 10px;
          border: none;
          cursor: pointer;
          margin-top: 10px;
        }

        .modal-actions {
          display: flex;
          justify-content: center;
          gap: 15px;
          margin-top: 20px;
        }

        .cancel-delete {
          background-color: #e0e0e0;
          color: black;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .confirm-delete {
          background-color: ${catalog?.theme_color ? catalog.theme_color : '#333'};
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .rest-overlay {
          padding-top: 50px;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          z-index: 1000;
        }

        .rest-title {
          color: ${catalog?.theme_color || '#333'};
          font-size: 1.7rem;
          margin-bottom: 20px;
        }

        .rest-content {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 40px;
          margin-bottom: 30px;
        }

        .qr-and-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
        }

        .scan-text {
          font-size: 1rem;
          color: ${catalog?.theme_color || '#333'};
        }

        .return-button {
          background-color: ${catalog?.theme_color || '#333'};
          color: white;
          border: none;
          padding: 15px 30px;
          border-radius: 5px;
          font-size: 1.2rem;
          cursor: pointer;
        }

        .return-button-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-align: center;
          transform: translateY(-20%);
        }

        .rest-message {
          margin-bottom: -20px;
          font-size: 1.75rem;
          color: ${catalog?.theme_color || '#333'};
          text-align: left;
        }
      `}</style>
    </div>
  );
};

export default Catalog;
