import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const apiBaseUrl = 'https://estoque-server-57ef3066a307.herokuapp.com';

const useDebouncePhone = (valuePhone, delayPhone) => {
  const [debouncedValuePhone, setDebouncedValuePhone] = useState(valuePhone);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValuePhone(valuePhone);
    }, delayPhone);

    return () => {
      clearTimeout(handler);
    };
  }, [valuePhone, delayPhone]);

  return debouncedValuePhone;
};

const useDebounceName = (valueName, delayName) => {
  const [debouncedValueName, setDebouncedValueName] = useState(valueName);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValueName(valueName);
    }, delayName);

    return () => {
      clearTimeout(handler);
    };
  }, [valueName, delayName]);

  return debouncedValueName;
};

const useDebounceEmail = (valueEmail, delayEmail) => {
  const [debouncedValueEmail, setDebouncedValueEmail] = useState(valueEmail);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValueEmail(valueEmail);
    }, delayEmail);

    return () => {
      clearTimeout(handler);
    };
  }, [valueEmail, delayEmail]);

  return debouncedValueEmail;
};

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [cart, setCart] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState('store');
  const [company, setCompany] = useState({ zip_code: '', full_address: '' });
  const [clientId, setClientId] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [newClient, setNewClient] = useState({
    phone: '',
    name: '',
    email: '',
    zip_code: '',
    full_address: '',
  });
  const [showRestartModal, setShowRestartModal] = useState(false);

  const debouncedPhone = useDebouncePhone(newClient.phone, 300);
  const debouncedName = useDebounceName(newClient.name, 300);
  const debouncedEmail = useDebounceEmail(newClient.email, 300);

  useEffect(() => {
    // Match the phone from URL or newClient state with the client list on page load
    if (newClient.phone) {
      const matchedClient = clientsList.find(client => client.phone === newClient.phone);
  
      if (matchedClient) {
        setNewClient({
          phone: matchedClient.phone,
          name: matchedClient.name,
          email: matchedClient.email,
          zip_code: matchedClient.zip_code,
          full_address: matchedClient.full_address,
        });
        setClientId(matchedClient.id);
      } else {
        setClientId('');
      }
    }
  }, [clientsList, newClient.phone]);

  useEffect(() => {
    if (debouncedPhone) {
      const matchedClient = clientsList.find(client => client.phone === debouncedPhone);
  
      if (matchedClient) {
        setNewClient(prevClient => ({
          ...prevClient,
          phone: matchedClient.phone,
          name: matchedClient.name,
          email: matchedClient.email,
          zip_code: matchedClient.zip_code,
          full_address: matchedClient.full_address,
        }));
        setClientId(matchedClient.id);
      } else {
        if (clientId) setClientId('');
        setNewClient(prevClient => ({
          ...prevClient,
          phone: debouncedPhone, // Retain phone
        }));
      }
  
      updateUrlParams({ newClient });
    }
  }, [debouncedPhone, clientsList]);

  useEffect(() => {
    if (debouncedName) {
      // Update only name in the state
      setNewClient(prevClient => ({
        ...prevClient,
        name: debouncedName
      }));
      updateUrlParams({ newClient });
    }
  }, [debouncedName]);
  
  useEffect(() => {
    if (debouncedEmail) {
      setNewClient(prevClient => ({
        ...prevClient,
        email: debouncedEmail // Update only email
      }));
      updateUrlParams({ newClient });
    }
  }, [debouncedEmail]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    
    const productIds = searchParams.get('products')?.split(',') || [];
    const companyId = searchParams.get('companyId');
    const catalogSlug = searchParams.get('catalogSlug');
    const deliveryOptionParam = searchParams.get('deliveryOption');
  
    if (companyId && catalogSlug && productIds.length > 0) {
      fetchCompanyData(companyId);
      fetchCatalogProductsData(catalogSlug, productIds);
      fetchClientsList(companyId); // Fetch the clients list when the company is loaded
    }
  
    if (clientId) {
      fetchClientData(clientId);
    }
  
    if (deliveryOptionParam) setDeliveryOption(deliveryOptionParam);
  
    const newClientParams = {
      phone: searchParams.get('phone') || '',
      name: searchParams.get('name') || '',
      email: searchParams.get('email') || '',
      zip_code: searchParams.get('zip_code') || '',
      full_address: searchParams.get('full_address') || '',
    };
    setNewClient(newClientParams);
  }, [location.search]);

  const fetchCompanyData = async (companyId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/companies/${companyId}`);
      setCompany(response.data);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const fetchCatalogProductsData = async (catalogSlug,productIds) => {
    try {
      const catalogResponse = await axios.get(`${apiBaseUrl}/catalogs/slug/${catalogSlug}`);
      const catalog = catalogResponse.data;

      const productsResponse = await axios.get(`${apiBaseUrl}/products/slug/${catalog.slug}`);
      const products = productsResponse.data;

      const cartItems = productIds.map(productIdQuantity => {
        const [id, quantity] = productIdQuantity.split(':');
        const product = products.find(p => p.id === id);

        if (product) {
          return { ...product, quantity: parseInt(quantity, 10) };
        }
        return null;
      }).filter(item => item !== null);

      setCart(cartItems);
    } catch (error) {
      console.error("Error fetching catalog and products data:", error);
    }
  };

  const fetchClientsList = async (companyId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/clients/${companyId}`);
      setClientsList(response.data); // Store the fetched clients list
    } catch (error) {
      console.error("Error fetching client list:", error);
    }
  };

  const fetchClientData = async (clientId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/clients/${clientId}`);
      setClientId(clientId);
      setNewClient(response.data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  const updateUrlParams = (changes = {}) => {
    const searchParams = new URLSearchParams(location.search);
    
    // Update only the changed fields
    if (changes.cart) {
      searchParams.set('products', changes.cart.map(item => `${item.id}:${item.quantity}`).join(','));
    }
  
    // if (changes.companyId) {
    //   searchParams.set('companyId', changes.companyId);
    // }
  
    // if (changes.catalogSlug) {
    //   searchParams.set('catalogSlug', changes.catalogSlug);
    // }
  
    if (changes.deliveryOption) {
      searchParams.set('deliveryOption', changes.deliveryOption);
    }
  
    if (changes.newClient) {
      Object.keys(changes.newClient).forEach(key => {
        if (changes.newClient[key] !== undefined && changes.newClient[key] !== '') {
          searchParams.set(key, changes.newClient[key]);
        } else {
          searchParams.delete(key);
        }
      });
    }
  
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, phone }));
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, name }));
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setNewClient(prevClient => ({ ...prevClient, email }));
  };

  const handleZipCodeChange = async (e) => {
    const zip = e.target.value;
    
    setNewClient(prevClient => ({ ...prevClient, zip_code: zip }));
  
    if (zip.length >= 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${zip}/json/`);
        const logradouro = response.data.logradouro || '';
  
        const updatedClient = { ...newClient, zip_code: zip, full_address: logradouro };
  
        setNewClient(updatedClient);
        updateUrlParams({ newClient: updatedClient });
        
      } catch (error) {
        console.error("Error fetching address data:", error);
      }
    }
  };

  const handleFullAddressChange = (e) => {
    const fullAddress = e.target.value;
  
    // Update the full_address state
    const updatedClient = { ...newClient, full_address: fullAddress };
    setNewClient(updatedClient);
  
    // Persist the updated full_address to the URL
    updateUrlParams({ newClient: updatedClient });
  };

  const handleRemoveItem = (index) => {
    setCart(prevCart => {
      if (prevCart.length === 1) {
        setShowRestartModal(true);
        return prevCart;
      } else {
        const updatedCart = prevCart.filter((_, i) => i !== index);
        updateUrlParams({ cart: updatedCart });
        return updatedCart;
      }
    });
  };

  const handleChangeQuantity = (index, amount) => {
    setCart(prevCart => {
      const updatedCart = prevCart.map((item, i) =>
        i === index ? { ...item, quantity: Math.max(1, item.quantity + amount) } : item
      );
      updateUrlParams({ cart: updatedCart });
      return updatedCart;
    });
  };

  const handleDeliveryOptionChange = (option) => {
    setDeliveryOption(option);
    
    const updatedClient = {
      ...newClient,
      zip_code: '',
      full_address: '',
    };

    setNewClient(updatedClient);
  
    // Use the updatedClient object directly to update the URL without waiting for state to sync
    updateUrlParams({ deliveryOption: option, newClient: updatedClient });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!clientId) {
      try {
        const clientData = {
          phone: newClient.phone,
          name: newClient.name,
          email: newClient.email,
          zip_code: newClient.zip_code,
          full_address: newClient.full_address
        };

        const clientResponse = await axios.post(`${apiBaseUrl}/clients/${company.id}`, clientData);
        setClientId(clientResponse.data.id);

        await createOrder(clientResponse.data.id);
      } catch (error) {
        console.error("Error creating client:", error);
      }
    } else {
      await createOrder(clientId);
    }
  };

  const createOrder = async (clientId) => {
    const items = cart.map(item => ({
      product_id: item?.id,
      name: item?.name,
      internet_url: item?.internet_url,
      quantity: item?.quantity,
      original_price: item?.original_price,
      price: item?.price,
      discount_percentage: item?.discount_percentage,
    }));

    const orderData = {
      description: `Delivery to ${deliveryOption}: ${deliveryOption === 'store' ? `${company.full_address} | ${company.zip_code}` : `${newClient.full_address} | ${newClient.zip_code}`}`,
      items: JSON.stringify(items),
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/orders/${clientId}`, orderData);

      const catalogSlug = location.search.split('catalogSlug=')[1].split('&')[0];
      const restartUrl = `/catalogs/${catalogSlug}`;
      navigate('/payment', { state: { orderId: response.data.id, cart, newClient, company, restartUrl } });
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  const calculateTotal = () => {
    return cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
  };

  const handleOpenRestartModal = () => {
    setShowRestartModal(true);
  };

  const handleCloseRestartModal = () => {
    setShowRestartModal(false);
  };

  return (
    <div className="checkout-container">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>Checkout</h1>
        <button
          style={{
            backgroundColor: '#333',
            color: '#fff',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
          onClick={() => {
            const productIds = cart.map(item => `${item.id}:${item.quantity}`).join(',');
            const searchParams = new URLSearchParams();
            searchParams.set('products', productIds);
            searchParams.set('companyId', company.id);
            searchParams.set('catalogSlug', location.search.split('catalogSlug=')[1].split('&')[0]);
            searchParams.set('name', newClient.name);
            searchParams.set('phone', newClient.phone);
            searchParams.set('email', newClient.email);

            const newUrl = `/catalogs/${searchParams.get('catalogSlug')}?${searchParams.toString()}`;

            navigate(newUrl);
          }}
        >
          Voltar à seleção
        </button>
      </div>
      <div className="checkout-header">
        <div className="header-item header-image"></div>
        <div className="header-item header-name">Name</div>
        <div className="header-item header-quantity">Quantity</div>
        <div className="header-item header-total">Total</div>
        <div className="header-item header-remove"></div>
      </div>

      {cart.length === 0 ? (
        <p>Sem items no carrinho</p>
      ) : (
        <div className="cart-items">
          {cart.map((item, index) => (
            <div key={index} className="cart-item">
              <img src={item.image_urls[0]} alt={item.name} className="cart-item-image" />
              <div className="cart-item-details">{item.name}</div>
              <div className="quantity-control">
                <button type="button" onClick={() => handleChangeQuantity(index, -1)} className="quantity-button">-</button>
                <span>{item.quantity}</span>
                <button type="button" onClick={() => handleChangeQuantity(index, 1)} className="quantity-button">+</button>
              </div>
              <div className="item-total">R$ {item.price * item.quantity}</div>
              <button type="button" className="remove-button" onClick={() => handleRemoveItem(index)}>Remover</button>
            </div>
          ))}
        </div>
      )}

      <div className="cart-total-container">
        <h3 className="cart-total">Total final: R$ {calculateTotal()}</h3>
        <button className="recomecar-button" onClick={handleOpenRestartModal}>
          <span className="recomecar-text">↺ Recomeçar</span>
        </button>
      </div>
      <div className="checkout-form">
        <form onSubmit={handleSubmit}>
          <div className="form">
            <h3>Cliente</h3>
            <input
              type="text"
              placeholder="Telefone (obrigatório)"
              value={newClient.phone}
              onChange={handlePhoneChange}
            />
            <input
              type="text"
              placeholder="Nome (obrigatório)"
              value={newClient.name}
              onChange={handleNameChange}
              disabled={!!clientId}
            />
            <input
              type="email"
              placeholder="Email (obrigatório)"
              value={newClient.email}
              onChange={handleEmailChange}
              disabled={!!clientId}
            />
          </div>
          <div className="delivery-options">
            <label className="delivery-option">
              <input
                type="radio"
                name="deliveryOption"
                value="store"
                checked={deliveryOption === 'store'}
                onChange={() => handleDeliveryOptionChange('store')}
              />
              Entregar no estabelecimento
            </label>
            <label className="delivery-option">
              <input
                type="radio"
                name="deliveryOption"
                value="address"
                checked={deliveryOption === 'address'}
                onChange={() => handleDeliveryOptionChange('address')}
              />
              Entregar no seu endereço
            </label>
          </div>
          {deliveryOption === 'address' && (
            <div className="address-fields">
              <input
                type="text"
                placeholder="CEP"
                value={newClient.zip_code}
                onChange={handleZipCodeChange}
                disabled={!!clientId}
              />
              <input
                type="text"
                placeholder="Endereço Completo"
                value={newClient.full_address}
                onChange={handleFullAddressChange}
                disabled={!!clientId}
              />
            </div>
          )}
          <button type="submit" disabled={(!newClient.phone || !newClient.name || !newClient.email)} style={{ opacity: (!newClient.phone || !newClient.name || !newClient.email) ? 0.6 : 1, cursor: (!newClient.phone || !newClient.name || !newClient.email) ? 'not-allowed' : 'pointer' }} className="checkout-submit-button">Ir para pagamento</button>
        </form>
      </div>

      {showRestartModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseRestartModal}>×</button>
            <p>Deseja remover todos os itens do seu carrinho e recomeçar?</p>
            <div className="modal-actions">
              <button className="cancel-restart" onClick={handleCloseRestartModal}>Não</button>
              <button className="confirm-restart" onClick=
                {() => {
                  setCart([]);
                  const catalogSlug = location.search.split('catalogSlug=')[1].split('&')[0];
                  const restartUrl = `/catalogs/${catalogSlug}`;
                  navigate(restartUrl);
                  setShowRestartModal(false);
                }}>Sim
              </button>
            </div>
          </div>
        </div>
      )}

      <style>{`
        .checkout-container {
          min-height: 100vh;
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          font-family: Arial, sans-serif;
        }
        .checkout-header, .cart-item {
          display: grid;
          grid-template-columns: 80px 2fr 1fr 1fr 1fr;
          align-items: center;
          text-align: center;
          padding: 10px 0;
        }
        .checkout-header {
          border-bottom: 2px solid #ddd;
          font-weight: bold;
        }
        .cart-item {
          border-bottom: 1px solid #ddd;
          padding: 10px 0;
        }
        .cart-item-image {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 5px;
        }
        .header-name, .header-total, .cart-item-details, .item-total {
          font-weight: bold;
        }
        .quantity-control {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .quantity-button {
          padding: 5px 10px;
          font-size: 1rem;
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
        }
        .quantity-button:hover {
          background-color: #ddd;
        }
        .quantity-control span {
          margin: 0 10px;
          font-size: 1.2rem;
        }
        .remove-button {
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s, color 0.3s;
        }
        .remove-button:hover {
          background-color: #ddd;
        }
        .cart-total-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
        }
        .cart-total {
          font-size: 1rem;
          font-weight: bold;
        }
        .trash-button {
          background-color: transparent;
          color: #333;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
        }
        .checkout-form {
          margin-top: 40px;
        }
        .form input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .address-fields {
          margin-top: 20px;
        }
        .address-fields input {
          display: block;
          width: 95%;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .checkout-submit-button {
          padding: 10px;
          font-size: 1rem;
          background-color: transparent;
          color: #333;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
          transition: background-color 0.3s, color 0.3s;
        }
        .checkout-submit-button:hover {
          background-color: #ddd;
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 5px;
          max-width: 400px;
          width: 100%;
          position: relative;
        }
        .close-modal {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 1.5rem;
          background: none;
          border: none;
          cursor: pointer;
        }
        .modal-actions {
          display: flex;
          justify-content: center;
          gap: 15px;
          margin-top: 20px;
        }
        .cancel-restart {
          background-color: #e0e0e0;
          color: black;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .confirm-restart {
          background-color: #333;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .recomecar-button {
          width: 30%;
          padding: 15px 20px;
          background-color: #333;
          color: #fff;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.3s;
          position: fixed;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
        }
        .recomecar-button:hover {
          background-color: #333;
          opacity: 0.75;
        }
      `}</style>
    </div>
  );
};

export default Checkout;
